<template>
    <div>
        <DocumentNotLoaded v-if="notLoaded" :document-title="'message.invoice'"/>
        <template v-if="!notLoaded">
            <appic-page-title-bar v-if="!notLoaded" ref="breadcrumbBar" :documentTitle="updateMode ? Invoice__invno : ''" help-page-component="AddInvoice"/>
            <app-section-loader :status="loader"/>
            <v-container fluid grid-list-xl pb-0 pt-3 px-3>
                <v-layout row wrap>
                    <InvoiceAlert
                            :alert="$t('message.closeAlert')"
                            class="mt-3"
                            mode="add"
                            type="warning"
                            v-if="addMode && newInvoiceUnsaved"
                    >
                    </InvoiceAlert>
                    <InvoiceAlert
                            :alert="$t('message.closeAlert')"
                            class="mt-3"
                            mode="update"
                            type="warning"
                            v-if="updateMode && updatedInvoiceUnsaved"
                    >
                    </InvoiceAlert>
                    <app-card
                            :fullBlock="true"
                            colClasses="xl12 lg12 md12 sm12 xs12"
                    >
                        <v-form lazy-validation ref="invoiceForm" v-model="validForm">
                            <v-overlay
                                    :value="loader"
                                    absolute
                                    opacity="0.15"
                            >
                                <v-row>
                                    <v-col class="text-center">
                                        <v-progress-circular
                                                color="primary"
                                                indeterminate
                                                size="40"
                                                width="6"
                                        ></v-progress-circular>
                                    </v-col>
                                </v-row>
                            </v-overlay>
                            <v-expansion-panels
                                    accordion
                                    multiple
                                    v-model="panel"
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content class="dense-inputs">
                                        <InvoiceGeneralInfo v-if="loader"></InvoiceGeneralInfo>
                                        <template v-if="!loader">
                                            <v-layout row pt-6 v-if="updateMode == true">
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveInvoice') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>
                                                    <v-autocomplete
                                                            :items="allActiveInvoices"
                                                            :placeholder="$t('message.startTypingToSearch')"
                                                            dense
                                                            hide-details
                                                            item-value="Invoice.id"
                                                            item-text="Invoice.invno"
                                                            required
                                                            solo
                                                            @change="loadAnotherActiveInvoice($event)"
                                                    >
                                                        <template v-slot:item="{item}">
                                                            <div class="d-flex flex-column py-1">
                                                                <span class="font-weight-bold">{{ item.Invoice.invno }}</span>
                                                                <span class="font-xs"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                                <span class="font-xs"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                            </div>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-flex>
                                            </v-layout>
                                            <!--                                    <v-layout row pt-3 v-if="addMode == true">-->
                                            <!--                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="red&#45;&#45;text align-center">{{ $t('message.invoiceType') }}</v-flex>-->
                                            <!--                                        <v-flex xs6 lg5 md6 py-1>-->
                                            <!--                                            <v-radio-group-->
                                            <!--                                                    dense-->
                                            <!--                                                    hide-details-->
                                            <!--                                                    row-->
                                            <!--                                                    v-model="Invoice__type"-->
                                            <!--                                            >-->
                                            <!--                                                <v-radio-->
                                            <!--                                                        :label="$t('message.directSales')"-->
                                            <!--                                                        value="ds"-->
                                            <!--                                                ></v-radio>-->
                                            <!--                                                <v-radio-->
                                            <!--                                                        :label="$t('message.warehouseSales')"-->
                                            <!--                                                        value="wh"-->
                                            <!--                                                ></v-radio>-->
                                            <!--                                                <v-radio-->
                                            <!--                                                        :label="$t('message.manual')"-->
                                            <!--                                                        value="manual"-->
                                            <!--                                                        v-if="false"-->
                                            <!--                                                ></v-radio>-->
                                            <!--                                            </v-radio-group>-->
                                            <!--                                        </v-flex>-->
                                            <!--                                    </v-layout>-->
                                            <v-layout row pt-3 v-if="Invoice__type == 'ds' && addMode == true">
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.relatedContract') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>
                                                    <v-autocomplete
                                                            :items="allUnInvoicedContracts"
                                                            :label="Invoice__Contract__id ? '' : $t('message.required')"
                                                            :loading="loadingRelatedContract"
                                                            :value="Invoice__Contract__id"
                                                            :item-text="item => [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-')"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            item-value="Contract.id"
                                                            required
                                                            return-object
                                                            solo
                                                            @change="unInvoicedContract = $event"
                                                    >
                                                        <template v-slot:item="{item}">
                                                            <div class="d-flex flex-column py-1">
                                                                <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                                <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                            </div>
                                                        </template>
                                                        <template v-slot:selection="{item}">
                                                            <span>{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row v-if="Invoice__type == 'wh' && addMode == true">
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.relatedSalesOrder') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>
                                                    <v-autocomplete
                                                            :items="allUnInvoicedSalesOrders"
                                                            :label="Invoice__Localsalesorder__id ? '' : $t('message.required')"
                                                            :loading="loadingRelatedSalesOrder"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            item-value="Localsalesorder.id"
                                                            item-text="Localsalesorder.title"
                                                            required
                                                            solo
                                                            v-model="Invoice__Localsalesorder__id"
                                                            @click="loadingRelatedSalesOrder = true"
                                                            @blur="loadingRelatedSalesOrder = false"
                                                    >
                                                        <template v-slot:item="{item}">
                                                            <div class="d-flex flex-column py-1">
                                                                <span class="font-weight-bold">{{ item.Localsalesorder.title }}</span>
                                                                <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                            </div>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row v-if="Invoice__type == 'ds' && updateMode == true">
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.contractNumber') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>{{ Invoice__Contract__version != null ? [Invoice__Contract__title, Invoice__Contract__revision_no , Invoice__Contract__partial_no].filter(Boolean).join('-') : Invoice__Contract__title }}</v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 v-if="Invoice__type == 'wh' || Invoice__type == 'ds'">{{ Invoice__Customer__title }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 v-else>
                                                    <v-autocomplete
                                                            :items=allBuyers
                                                            :label="Manual__Invoice__customer_id ? '' : $t('message.required')"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            item-text="Customer.title"
                                                            item-value="Customer.id"
                                                            required
                                                            solo
                                                            v-model="Manual__Invoice__customer_id"
                                                            @change="buyerChanged"
                                                    ></v-autocomplete>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.buyerTerms') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>{{ Invoice__Paymentstatement__title != '' ? Invoice__Paymentstatement__title : $t('message.buyerTermMissing') }}</v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.appTimberCompany') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 v-if="Invoice__type == 'wh' || Invoice__type == 'ds'">{{ Invoice__Office__title }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 v-else>
                                                    <v-select
                                                            :items=offices
                                                            :label="Manual__Invoice__office_id ? '' : $t('message.required')"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            item-text="Office.title"
                                                            item-value="Office.id"
                                                            required
                                                            solo
                                                            v-model="Manual__Invoice__office_id"
                                                            @change="officeChanged"
                                                    ></v-select>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.invoiceNumber') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>
                                                    <v-text-field
                                                            :value="Invoice__invno"
                                                            :label="Invoice__invno ? '' : $t('message.required')"
                                                            :rules="[rules.number]"
                                                            autocomplete="new-password"
                                                            class="force-text-left"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            solo
                                                            required
                                                            @change="Invoice__invno = $event"
                                                    >
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.invoiceDate') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>
                                                    <v-menu
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            max-width="290px"
                                                            min-width="290px"
                                                            ref="invoiceDateMenu"
                                                            transition="scale-transition"
                                                            v-model="invoiceDateMenu"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                    :label="Invoice__invdate ? '' : $t('message.required')"
                                                                    :rules="[rules.date]"
                                                                    :value="computedInvoiceDateFormatted"
                                                                    append-icon="event"
                                                                    clearable
                                                                    class="force-text-left"
                                                                    hint="DD/MM/YYYY format"
                                                                    hide-details="auto"
                                                                    background-color="white"
                                                                    persistent-hint
                                                                    solo
                                                                    v-on="on"
                                                                    @click:clear="Invoice__invdate = null"
                                                                    @click:append="invoiceDateMenu = true"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                                first-day-of-week="1"
                                                                locale-first-day-of-year="4"
                                                                show-week
                                                                v-model="Invoice__invdate"
                                                                @input="invoiceDateMenu = false"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.posted') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1>{{ Invoice__is_posted == 1 ? $t('message.yes'): $t('message.no') }}</v-flex>
                                            </v-layout>
                                        </template>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.specifications') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content class="dense-inputs">
                                        <Transition>
                                            <InvoiceItemList v-if="loader"/>
                                        </Transition>
                                        <v-flex lg12 mb-0 pb-0 mt-3 pt-0 v-if="Invoice__type == 'ds' && Invoice__Contract__id == null">
                                            <v-alert
                                                    border="left"
                                                    color="warning"
                                                    dense
                                                    icon="warning"
                                                    text
                                                    width="500px"
                                            >{{ $t('message.errors.noRelatedContractSelected') }}</v-alert>
                                        </v-flex>
                                        <v-flex lg12 mb-0 pb-0 mt-3 pt-0 v-if="Invoice__type == 'wh' && Localsalesorder__id == null">
                                            <v-alert
                                                    border="left"
                                                    color="warning"
                                                    dense
                                                    icon="warning"
                                                    text
                                                    width="500px"
                                            >{{ $t('message.errors.noRelatedLocalsalesorderSelected') }}</v-alert>
                                        </v-flex>
                                        <template v-if="!loader">
                                            <InvoiceSalesItemList :updateMode="this.updateMode" v-if="Invoice__type == 'ds' && Invoice__Contract__id != null"/>
                                            <LocalSalesItemList :updateMode="this.updateMode" v-else-if="Invoice__type == 'wh' && Invoice__Localsalesorder__id != null"/>
                                            <ManualInvoiceItemList :updateMode="this.updateMode" v-else-if="Invoice__type == 'manual'"/>
                                        </template>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.finance') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content class="dense-inputs">
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.bankDetails') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="filteredBankAccounts"
                                                        clearable
                                                        dense
                                                        item-value="Bank.id"
                                                        item-text="Bank.title"
                                                        hide-details
                                                        solo
                                                        v-model="Invoice__bank_id"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-2">
                                                            <span class="font-sm">{{ item.Bank.title }}</span>
                                                            <span class="font-weight-bold"> {{ br2nl(item.Bank.account) }} </span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{item}">
                                                        <div class="d-flex flex-column py-2">
                                                            <span class="font-sm">{{ item.Bank.title }}</span>
                                                            <span class="font-weight-bold"> {{ br2nl(item.Bank.account) }} </span>
                                                        </div>
                                                    </template>
                                                </v-select>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.gstVatPpnRate') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="gstRates"
                                                        :label="Invoice__gstpercent ? '' : $t('message.required')"
                                                        clearable
                                                        dense
                                                        hide-details
                                                        item-value="Gstrate.value"
                                                        item-text="Gstrate.title"
                                                        solo
                                                        required
                                                        v-model="Invoice__gstpercent"
                                                >
                                                </v-select>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.exchangeRateForGstVatPpn') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-text-field
                                                        :value="Invoice__gstrmrate"
                                                        class="force-text-left"
                                                        clearable
                                                        dense
                                                        hide-details
                                                        solo
                                                        type="number"
                                                        @change="Invoice__gstrmrate = $event"
                                                >
                                                </v-text-field>
                                            </v-flex>
                                        </v-layout>
                                        <!--                                    <v-layout row>-->
                                        <!--                                        <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.exchangeRateForGstVatPpn') }}</v-flex>-->
                                        <!--                                        <v-flex xs6 lg4 md6 py-1>-->
                                        <!--                                            <v-text-field-->
                                        <!--                                                :value="Invoice__gstrmrate"-->
                                        <!--                                                class="force-text-left"-->
                                        <!--                                                clearable-->
                                        <!--                                                dense-->
                                        <!--                                                hide-details-->
                                        <!--                                                solo-->
                                        <!--                                                type="number"-->
                                        <!--                                                @change="Invoice__gstrmrate = $event"-->
                                        <!--                                            >-->
                                        <!--                                            </v-text-field>-->
                                        <!--                                        </v-flex>-->
                                        <!--                                    </v-layout>-->
                                        <v-layout>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.salesColleague') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ Invoice__Salescontact__name }}</v-flex>
                                        </v-layout>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.operations') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-2 class="align-center">{{ $t('message.remarks') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-textarea
                                                        :value="Invoice__remark"
                                                        clearable
                                                        dense
                                                        hide-details
                                                        rows="3"
                                                        solo
                                                        @change="Invoice__remark = $event"
                                                ></v-textarea>
                                            </v-flex>
                                        </v-layout>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.printPreferences') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-2 class="align-center">{{ $t('message.addressToUse') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items=addressesToUse
                                                        clearable
                                                        dense
                                                        hide-details
                                                        solo
                                                        v-model="Invoice__usenotifyaddress"
                                                >
                                                </v-select>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="Invoice__usenotifyaddress == 2">
                                            <v-flex xs6 lg2 md3 pl-5 py-1></v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-textarea
                                                        :value="Invoice__otheraddress"
                                                        clearable
                                                        dense
                                                        hide-details
                                                        rows="3"
                                                        solo
                                                        @change="Invoice__otheraddress = $event"
                                                >
                                                </v-textarea>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.showSignatureBox') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-checkbox
                                                        class="mt-0 pt-0"
                                                        dense
                                                        hide-details
                                                        v-model="Invoice__json_show_sign_box"
                                                ></v-checkbox>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="false">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.hideForexConversion') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-checkbox
                                                        :true-value="1"
                                                        :false-value="null"
                                                        class="mt-0 pt-0"
                                                        dense
                                                        hide-details
                                                        v-model="Invoice__hide_conversion"
                                                ></v-checkbox>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.hideSignatureStamp') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-checkbox
                                                        class="mt-0"
                                                        dense
                                                        hide-details
                                                        v-model="Invoice__hidestamp"
                                                ></v-checkbox>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.preferredFooterNotes') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-btn @click.stop="footerNotesDialog = true" small>{{ $t('message.manage') }}</v-btn>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.printTwoPages') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-checkbox
                                                        :true-value="1"
                                                        class="mt-0"
                                                        dense
                                                        hide-details
                                                        solo
                                                        v-model="Invoice__longform"
                                                >
                                                </v-checkbox>
                                            </v-flex>
                                        </v-layout>
                                        <template v-if="$can('create','TemporaryInvoice') && updateMode == true && [1].includes(Invoice__Contract__office_id)">
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="grey lighten-3 align-center">{{ $t('message.temporaryCurrency') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 class="grey lighten-3">
                                                    <v-select
                                                            :items="allCurrencies"
                                                            class="custom py-0 mt-0 bg-lightgrey"
                                                            hide-details
                                                            item-text="Currency.code"
                                                            item-value="Currency.id"
                                                            reverse
                                                            v-model="Invoice__tmp_currency_id"
                                                    >
                                                    </v-select>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="grey lighten-3 align-center">{{ $t('message.temporaryExchangeRate') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 class="grey lighten-3">
                                                    <v-text-field
                                                            :value="Invoice__tmp_currencyrate"
                                                            class="custom py-0 mt-0 bg-lightgrey"
                                                            dense
                                                            hide-details
                                                            type="number"
                                                            @change="Invoice__tmp_currencyrate = $event"
                                                    >
                                                    </v-text-field>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class="grey lighten-3 align-center">{{ $t('message.temporaryExchangeRateDate') }}</v-flex>
                                                <v-flex xs6 lg4 md6 py-1 class="grey lighten-3">
                                                    <v-menu
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            max-width="290px"
                                                            min-width="290px"
                                                            ref="invoiceDateMenu"
                                                            transition="scale-transition"
                                                            v-model="temporaryInvoiceDateMenu"
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                    :value="computedTemporaryInvoiceDateFormatted"
                                                                    append-icon="event"
                                                                    clearable
                                                                    class="force-text-left"
                                                                    hint="DD/MM/YYYY format"
                                                                    hide-details="auto"
                                                                    background-color="white"
                                                                    persistent-hint
                                                                    solo
                                                                    v-on="on"
                                                                    @click:clear="Invoice__tmp_invdate = null"
                                                                    @click:append="temporaryInvoiceDateMenu = true"
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                                first-day-of-week="1"
                                                                locale-first-day-of-year="4"
                                                                show-week
                                                                v-model="Invoice__tmp_invdate"
                                                                @input="temporaryInvoiceDateMenu = false"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout row>
                                                <v-flex xs12 lg6 md9 pt-1 py-4 pl-3 class="grey lighten-3">
                                                    <div>
                                                        <v-btn
                                                                :loading="loading.saveTemp"
                                                                class="ml-3"
                                                                small
                                                                @click.stop="saveTemporaryInvoiceFields"
                                                        >
                                                            {{ $t('message.save') }}
                                                        </v-btn>
                                                        <v-btn class="ml-3" small @click.stop="viewTemporaryInvoicePdf">
                                                            <v-icon small>far fa-file-pdf</v-icon>
                                                            <span class="ml-1">{{ $t('message.temporaryInvoice') }}</span>
                                                        </v-btn>
                                                    </div>
                                                </v-flex>
                                            </v-layout>
                                        </template>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel v-if="false">
                                    <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.forGpIntegration') }}</v-expansion-panel-header>
                                    <v-expansion-panel-content class="dense-inputs">
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.gpTaxCode') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="gpTaxCodes"
                                                        clearable
                                                        dense
                                                        hide-details
                                                        item-value="Taxcode.title"
                                                        item-text="Taxcode.title"
                                                        required
                                                        solo
                                                        v-model="Invoice__headertaxschedule"
                                                ></v-select>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.customerNumber') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ Invoice__Company__GPCustomerNumber != null && Invoice__Company__GPCustomerNumber.length > 0 ? Invoice__Company__GPCustomerNumber : $t('message.gpCustomerNumberRequired') }}</v-flex>
                                        </v-layout>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_invoice')">
                                        <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <DocumentHistory asset="invoices" :asset_id="Invoice__id"></DocumentHistory>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <v-layout row>
                                <v-flex d-flex xs12>
                                    <v-layout row px-3 pt-3 mb-3>
                                        <v-btn
                                                @click.stop="previewDialog = true"
                                                small
                                                class="ml-0"
                                        >{{ $t('message.preview') }}
                                        </v-btn>
                                        <PrintDirectSalesInvoiceBlob
                                                :contract-currency-id="Invoice__Contract__currency_id"
                                                :destination-id="Invoice__Contract__destinatione_id"
                                                :invoice-id="invoiceId"
                                                :invoice-currency-id="Invoice__currency_id"
                                                :office-id="Invoice__Contract__office_id"
                                                :update-mode="updateMode"
                                                :list-mode="false"
                                                :sales-type-id="Invoice__Contract__salestype_id"
                                        />
                                        <v-btn
                                                :loading="loading.reset"
                                                @click="resetInvoiceForm"
                                                class="ml-3"
                                                color="default"
                                                small
                                                v-if="addMode == true"
                                        >
                                            <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                        </v-btn>
                                        <v-btn
                                                :loading="loading.save"
                                                class="ml-3"
                                                color="info"
                                                small
                                                @click="saveInvoice"
                                                v-if="addMode == false && Invoice__is_posted != 1"
                                        >
                                            {{ $t('message.save') }}
                                        </v-btn>
                                        <v-btn
                                                :loading="loading.cancel"
                                                class="ml-3"
                                                color="error"
                                                small
                                                v-if="addMode == false && Invoice__is_posted != 1"
                                                @click="cancelInvoice(Invoice__id)"
                                        >{{ $t('message.cancel') }}
                                        </v-btn>
                                        <v-btn
                                                :loading="loading.xero"
                                                class="ml-3"
                                                small
                                                v-if="updateMode == true && Invoice__is_posted != 1"
                                                @click="uploadToXero(Invoice__id)"
                                        >{{ $t('message.uploadToXero') }}
                                        </v-btn>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-form>
                    </app-card>
                </v-layout>
            </v-container>
        </template>
        <v-dialog
            persistent
            v-model="previewDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.invoicePreview') }}</v-card-title>
                <v-card-text class="pa-3">
                    <InvoicePreview/>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="previewDialog = false"
                        class="ml-3"
                        color="default"
                        small
                    >{{ $t('message.backToEditing') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.add"
                        class="ml-3"
                        color="info"
                        small
                        @click="addInvoice"
                        v-if="addMode == true"
                    >{{ $t('message.saveNewInvoice') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.save"
                        class="ml-3"
                        color="info"
                        small
                        v-if="addMode == false && Invoice__is_posted != 1"
                        @click="saveInvoice"
                    >{{ $t('message.saveChanges') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            v-model="footerNotesDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.manageFooterNotes') }}</v-card-title>
                <v-card-text>
                    <v-list dense>
                        <v-list-item v-for="item in invoiceFooterNotes" :key="item.id" v-if="item.id != 10">
                            <v-list-item-action>
                                <v-checkbox
                                        :value="item.id"
                                        dense
                                        hide-details
                                        v-model="Invoice__footer_notes"
                                ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="font-sm wrap-text">{{ parseFooterNote(item) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            @click="footerNotesDialog = false"
                            class="ml-3"
                            color="default"
                            small
                    >{{ $t('message.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <SimpleAlert
                :alert_message="this.dialogs.error_message"
                v-bind:dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        >
        </SimpleAlert>
<!--        <PrintInvoiceHtml :invoice-id="invoiceId" :view-pdf="viewPdf" :key="pdfComponentKey" @pdf-has-downloaded="resetViewVars()" :is-tax-invoice="pdfTaxInvoice"></PrintInvoiceHtml>-->
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {mapFields} from "vuex-map-fields";
    import {formatDate, br2nl, scrollToFirstFormValidationError, log} from "Helpers/helpers";
    // import InvoiceAlert from "../../../components/Appic/InvoiceAlert";
    // import InvoiceSalesItemList from "../../../components/Appic/InvoiceSalesItemList";
    // import LocalSalesItemList from "../../../components/Appic/LocalSalesItemList";
    // import SimpleAlert from "../../../components/Appic/SimpleAlert";
    // import InvoicePreview from "../../../components/Appic/InvoicePreview";
    // import ManualInvoiceItemList from "../../../components/Appic/ManualInvoiceItemList";
    import {mapFieldsPrefixed} from "../../../helpers/vuex-map-fields-prefixed";
    import router from "../../../router";
    // import DocumentHistory from "../../../components/Appic/DocumentHistory";
    import { v4 as uuidv4 } from 'uuid';
    import {api} from "Api";
    import InvoiceItemList from "Components/Appic/Loading/InvoiceItemList";
    import InvoiceGeneralInfo from "Components/Appic/Loading/InvoiceGeneralInfo";
    import DocumentNotLoaded from "Components/Appic/Error/DocumentNotLoaded.vue";
    // import PrintInvoiceHtml from "Views/v1/invoices/PrintInvoiceHtml";

    const DocumentHistory = () => import("Components/Appic/DocumentHistory");
    const InvoiceAlert = () => import("Components/Appic/InvoiceAlert");
    const InvoicePreview = () => import("Components/Appic/InvoicePreview");
    const InvoiceSalesItemList = () => import("Components/Appic/InvoiceSalesItemList");
    const LocalSalesItemList = () => import("Components/Appic/LocalSalesItemList");
    const ManualInvoiceItemList = () => import("Components/Appic/ManualInvoiceItemList");
    // const PrintInvoiceHtml = () => import('Views/v1/invoices/PrintInvoiceHtml');
    const SimpleAlert = () => import("Components/Appic/SimpleAlert");
    const PrintDirectSalesInvoiceBlob = () => import('Components/Appic/Printing/PrintDirectSalesInvoiceButton')

    export default {
        name: "AddInvoice",
        components: {
            DocumentNotLoaded,
            InvoiceGeneralInfo,
            InvoiceItemList,
            DocumentHistory,
            InvoiceAlert,
            InvoicePreview,
            InvoiceSalesItemList,
            LocalSalesItemList,
            ManualInvoiceItemList,
            // PrintInvoiceHtml,
            PrintDirectSalesInvoiceBlob,
            SimpleAlert
        },
        props: ['invoiceId','contractId'],
        title: '',
        data() {
            return {
                addressesToUse: [
                    {value: 0, text: this.$t('message.customerMainAddress')},
                    {value: 1, text: this.$t('message.customerDeliveryNotifyAddress') },
                    {value: 2, text: this.$t('message.otherAddress') }
                ],
                allUnInvoicedContracts: [],
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                footerNotesDialog: false,
                invoiceDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
                invoiceDateMenu: false,
                loader: false,
                loading: {
                    add: false,
                    invoicePdf: false,
                    post: false,
                    reset: false,
                    save: false,
                    saveTemp: false,
                    taxInvoicePdf: false,
                    xero: false
                },
                loadingActiveInvoice: false,
                loadingRelatedContract: false,
                loadingRelatedSalesOrder: false,
                notLoaded: false,
                panel: [0,1],
                pdfComponentKey: 0,
                pdfTaxInvoice: false,
                previewDialog: false,
                rules: {
                    date: v => !!v || this.$t('message.required'),
                    number: v => !!v || this.$t('message.required'),
                },
                temporaryInvoiceDateMenu: false,
                unInvoicedContract: null,
                validForm: true,
                viewPdf: false
            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapGetters('buyer', {
                allBuyers: 'allBuyers',
                findBuyerById: 'findBuyerById'
            }),
            ...mapFieldsPrefixed('invoice',{
                Invoice__Company__GPCustomerNumber: 'Invoice.Company.GPCustomerNumber',
                Invoice__Contract__id: 'Invoice.Contract.id',
                Invoice__Contract__bank_id: 'Invoice.Contract.bank_id',
                Invoice__Contract__currency_id: 'Invoice.Contract.currency_id',
                Invoice__Contract__customer_id: 'Invoice.Contract.customer_id',
                Invoice__Contract__destinatione_id: 'Invoice.Contract.destinatione_id',
                Invoice__Contract__fsc: 'Invoice.Contract.fsc',
                Invoice__Contract__incoterm_id: 'Invoice.Contract.incoterm_id',
                Invoice__Contract__loadingport_id: 'Invoice.Contract.loadingport_id',
                Invoice__Contract__office_id: 'Invoice.Contract.office_id',
                Invoice__Contract__partial_no: 'Invoice.Contract.partial_no',
                Invoice__Contract__revision_no: 'Invoice.Contract.revision_no',
                Invoice__Contract__salestype_id: 'Invoice.Contract.salestype_id',
                Invoice__Contract__shippingport_id: 'Invoice.Contract.shippingport_id',
                Invoice__Contract__title: 'Invoice.Contract.title',
                Invoice__Contract__version: 'Invoice.Contract.version',
                Invoice__Customer__id: 'Invoice.Customer.id',
                Invoice__Customer__title: 'Invoice.Customer.title',
                Invoice__Deliveryaddress__address: 'Invoice.Deliveryaddress.address',
                Invoice__FreeEntry__id: 'Invoice.FreeEntry.id',
                Invoice__FreeEntry__bank_id: 'Invoice.FreeEntry.bank_id',
                Invoice__FreeEntry__currency_id: 'Invoice.FreeEntry.currency_id',
                Invoice__FreeEntry__office_id: 'Invoice.FreeEntry.office_id',
                Invoice__id: 'Invoice.id',
                Invoice__is_posted: 'Invoice.is_posted',
                Invoice__contract_id: 'Invoice.contract_id',
                Invoice__currency_id: 'Invoice.currency_id',
                Invoice__footer_notes: 'Invoice.footer_notes',
                Invoice__gstpercent: 'Invoice.gstpercent',
                Invoice__gstrmrate: 'Invoice.gstrmrate',
                Invoice__headertaxschedule: 'Invoice.headertaxschedule',
                Invoice__hide_conversion: 'Invoice.hide_conversion',
                Invoice__hidestamp: 'Invoice.hidestamp',
                Invoice__invdate: 'Invoice.invdate',
                Invoice__invno: 'Invoice.invno',
                Invoice__json_show_sign_box: 'Invoice.json.show_sign_box',
                Invoice__longform: 'Invoice.longform',
                Invoice__otheraddress: 'Invoice.otheraddress',
                Invoice__posted: 'Invoice.posted',
                Invoice__posted_by: 'Invoice.posted_by',
                Invoice__remark: 'Invoice.remark',
                Invoice__tmp_currency_id: 'Invoice.tmp_currency_id',
                Invoice__tmp_currencyrate: 'Invoice.tmp_currencyrate',
                Invoice__tmp_invdate: 'Invoice.tmp_invdate',
                Invoice__type: 'Invoice.type',
                Invoice__usenotifyaddress: 'Invoice.usenotifyaddress',
                // Invoice__Localsalesorder__id: 'Invoice.Localsalesorder.id',
                // Invoice__Localsalesorder__bank_id: 'Invoice.Localsalesorder.bank_id',
                // Invoice__Localsalesorder__customer_id: 'Invoice.Localsalesorder.customer_id',
                // Invoice__Localsalesorder__currency_id: 'Invoice.Localsalesorder.currency_id',
                // Invoice__Localsalesorder__office_id: 'Invoice.Localsalesorder.office_id',
                // Invoice__Localsalesorder__paymentstatement_id: 'Invoice.Localsalesorder.paymentstatement_id',
                // Invoice__Localsalesorder__title: 'Invoice.Localsalesorder.title',
                Invoice__Office__title: 'Invoice.Office.title',
                Invoice__Paymentstatement__title: 'Invoice.Paymentstatement.title',
                Invoice__Paymentterm__id: 'Invoice.Paymentterm.id',
                Invoice__Paymentterm__title: 'Invoice.Paymentterm.title',
                Invoice__Salescontact__name: 'Invoice.Salescontact.name'
            },'statePrefix'),
            ...mapFieldsPrefixed('manualinvoice',{
                Manual__Invoice__id: 'Manual.Invoice.id',
                Manual__Invoice__bank_id: 'Manual.Invoice.bank_id',
                Manual__Invoice__customer_id: 'Manual.Invoice.customer_id',
                Manual__Invoice__currency_id: 'Manual.Invoice.currency_id',
                Manual__Invoice__office_id: 'Manual.Invoice.office_id',
                Manual__Invoice__paymentstatement_id: 'Manual.Invoice.paymentstatement_id',
                Manual__Invoice__title: 'Manual.Invoice.title',
            },'statePrefix'),
            ...mapFields('invoice',{
                newInvoiceSaved: 'newInvoiceSaved',
                newInvoiceUnsaved: 'newInvoiceUnsaved',
                updatedInvoiceUnsaved: 'updatedInvoiceUnsaved',
                updatedInvoiceSaved: 'updatedInvoiceSaved'
            }),
            ...mapFields('invoicesalesitem',{
                Current__Salesitems: 'current.Salesitems'
            }),
            ...mapFieldsPrefixed('invoicesalesitem',{
                Salesitem__gstpercent: 'gstpercent',
            },'statePrefix'),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('bankaccount', {
                allBankAccounts: 'allBankAccounts',
                findBankAccountById: 'findBankAccountById',
                findBankAccountsByOfficeAndCurrency: 'findBankAccountsByOfficeAndCurrency',
            }),
            ...mapGetters('contract',{
                getContractById: 'getContractById',
                // allUnInvoicedContracts: 'allUnInvoicedContracts'
            }),
            ...mapGetters('currency', {
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('invoice',{
                allActiveInvoices: 'allActiveInvoices',
            }),
            ...mapGetters('salesorder',{
                getSalesOrderById: 'getSalesOrderById',
                allUnInvoicedSalesOrders: 'allUnInvoicedSalesOrders'
            }),
            ...mapGetters('user',{
                User: 'User'
            }),
            ...mapGetters([
                'gpTaxCodes',
                'gstRates',
                'invoiceFooterNotes',
                'offices',
                'statuses'
            ]),
            addMode: function () {
                return this.$route.matched.some(({name}) => name === 'add_invoice')
            },
            allowPosting: function () {
                return this.User.email == 'vanjoe@apptimber.com' // temporary
            },
            br2nl: () =>  br2nl,
            computedInvoiceDateFormatted () {
                return formatDate(this.Invoice__invdate)
            },
            computedTemporaryInvoiceDateFormatted() {
                return formatDate(this.Invoice__tmp_invdate)
            },
            filteredBankAccounts: function(){
                switch(this.Invoice__type){
                    case 'ds':
                        if(this.Invoice__Contract__office_id != null && this.Invoice__Contract__currency_id != null){
                            return this.findBankAccountsByOfficeAndCurrency(this.Invoice__Contract__office_id, this.Invoice__Contract__currency_id)
                        } else {
                            return []
                        }
                        break;
                    case 'wh':
                        if(this.Localsalesorder__office_id != null && this.Localsalesorder__currency_id != null){
                            return this.findBankAccountsByOfficeAndCurrency(this.Localsalesorder__office_id, this.Localsalesorder__currency_id)
                        } else {
                            return []
                        }
                        break;
                    default:
                        return this.allBankAccounts
                }
            },
            footerNoteReplacements (){
                let obj = {}
                if([1,3].includes(this.Invoice__Contract__office_id)){
                    obj.interest_charge_pct = '1.5%'
                } else {
                    obj.interest_charge_pct = '2%'
                }
                return obj
            },
            Invoice__bank_id: {
                get () {
                    let val = null
                    switch (this.Invoice__type) {
                        case 'ds':
                            val = this.Invoice__Contract__bank_id
                            break;
                        case 'wh':
                            val = this.Localsalesorder__bank_id
                            break;
                        case 'un':
                            val = this.FreeEntry__bank_id
                            break;
                    }
                    return val
                },
                set (val) {
                    switch (this.Invoice__type) {
                        case 'ds':
                            this.Invoice__Contract__bank_id = val
                            break;
                        case 'wh':
                            this.Localsalesorder__bank_id = val
                            break;
                        case 'un':
                            this.FreeEntry__bank_id = val
                            break;
                    }
                }
            },
            // officeCurrencyId: function() {
            //     const office = this.offices.find(o => o.Office.id == this.Invoice__Contract__office_id)
            //     if(office) {
            //         const officeCurrency = this.allCurrencies.find(c => c.Currency.id == office.Office.currency_id)
            //         if(officeCurrency) return officeCurrency.Currency.id
            //     }
            //     return null
            // },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_invoice')
            }
        },
        methods: {
            ...mapActions('bankaccount',{
                getAllBankAccounts: 'getAllBankAccounts',
            }),
            ...mapActions('contract',{
                getAllActiveContracts: 'getAllActiveContracts',
                getAllContractsWithoutInvoice: 'getAllContractsWithoutInvoice'
            }),
            ...mapActions('currency',{
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('invoice', {
                cancelInvoiceById: 'cancelInvoiceById',
                createInvoice: 'createInvoice',
                getAllActiveInvoices: 'getAllActiveInvoices',
                getInvoiceToUpdateById: 'getInvoiceToUpdateById',
                loadUninvoicedContract: 'loadUninvoicedContract',
                resetAllActiveInvoices: 'resetAllActiveInvoices',
                resetCurrentInvoice: 'resetCurrentInvoice',
                resetInvoice: 'resetInvoice',
                updateInvoice: 'updateInvoice',
                updateTemporaryInvoiceFields: 'updateTemporaryInvoiceFields'
            }),
            ...mapActions('invoicesalesitem',{
                getSalesItemsByContract: 'getSalesItemsByContract',
                // getSalesItemsByInvoice: 'getSalesItemsByInvoice',
                resetInvoiceSalesItems: 'resetInvoiceSalesItems',
                updateTotals: 'updateTotals'
            }),
            ...mapActions('salesorder',{
                getAllActiveSalesOrders: 'getAllActiveSalesOrders'
            }),
            ...mapActions('localsalesitem',{
                getSalesItemsBySalesOrder: 'getSalesItemsBySalesOrder',
                getSalesItemsByLocalSale: 'getSalesItemsByLocalSale'
            }),
            addInvoice(){
                if(this.$refs.invoiceForm.validate()) {
                    this.loading.add = true
                    this.createInvoice()
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.invoiceAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.add = false
                                this.newInvoiceUnsaved = false
                                this.newInvoiceSaved = true
                                // reset new invoice state
                                this.resetCurrentInvoice().then(() => {
                                    //reset new invoice items state
                                    this.resetInvoiceSalesItems(this.statePrefix).then(() => {
                                        this.resetAllActiveInvoices().then(() => {
                                            router.push({
                                                name: 'update_invoice',
                                                params: {invoiceId: response.invoice_id}
                                            }).catch(err => {
                                            })
                                        })
                                    })
                                })
                            } else {
                                this.$toast.error(response,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.add = false
                            }
                        })
                        .catch((error) => {
                            this.$toast.error(error.message,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        })
                        .finally(() => {
                            this.loading.add = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.invoiceNotAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    scrollToFirstFormValidationError()
                }
            },
            buyerChanged () {
                // let buyer = this.findBuyerById(this.Manual__customer_id)
                // if(buyer != null){
                //
                // } else {
                //
                // }
            },
            async cancelInvoice(val) {
                if(await this.$root.$confirm(this.$t('message.cancelInvoice'), this.$t('message.confirmations.continueInvoiceCancelAction'), {color: 'orange'})){
                    this.cancelInvoiceById(val).then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.invoiceDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            router.push({name: 'invoice_listing'}).catch(err => {})
                            this.loading.cancel = false
                        } else {
                            this.$toast.error(this.$t('message.errors.invoiceNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.invoiceNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                }
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            loadAnotherActiveInvoice (val) {
                router.push({name: 'update_invoice', params: { invoiceId : val}}).catch(err => {})
            },
            // loadUninvoicedContract (val) {
            //     this.loader = true
            //     let self = this
            //     let contract = this.allUnInvoicedContracts.find((contract)=> contract.Contract.id == val )
            //     if(contract) {
            //         this.Invoice__Contract__bank_id = contract.Bank.id
            //         this.Invoice__Contract__currency_id = contract.Contract.currency_id
            //         this.Invoice__Contract__customer_id = contract.Customer.id
            //         this.Invoice__Contract__fsc = contract.Contract.fsc
            //         this.Invoice__Contract__incoterm_id = contract.Contract.incoterm_id
            //         this.Invoice__Contract__incotermport = contract.Contract.incotermport
            //         this.Invoice__Contract__loadingport_id = contract.Contract.loadingport_id
            //         this.Invoice__Contract__office_id = contract.Office.id
            //         this.Invoice__Contract__shippingport_id = contract.Contract.shippingport_id
            //         this.Invoice__Contract__title = contract.Contract.title
            //         if (contract.Contract.salestype_id == 5) {
            //             this.Invoice__currency_id = this.offices.find(office => office.Office.id === contract.Office.id)?.Office?.currency_id;
            //         } else {
            //             this.Invoice__currency_id = contract.Contract.currency_id
            //         }
            //         this.Invoice__contract_id = contract.Contract.id
            //         this.Invoice__Office__title = contract.Office.title
            //         this.Invoice__Customer__title = contract.Customer.title
            //         this.Invoice__Salescontact__name = contract.Salescontact.name
            //         this.Invoice__Paymentterm__id = contract.Paymentstatement.id
            //         this.Invoice__Paymentterm__title = contract.Paymentstatement.title
            //         // this.loadContractSalesItems(val)
            //         // this.loadingRelatedContract = false
            //         this.Invoice__headertaxschedule = this.offices.find(office => office.Office.id === contract.Office.id)?.Office?.taxcode
            //         this.Invoice__gstpercent = this.offices.find(office => office.Office.id === contract.Office.id)?.Office?.taxrate
            //
            //         let payload = {
            //             contract_id: val,
            //             prefix: self.statePrefix
            //         }
            //         this.getSalesItemsByContract(payload).then(()=>{
            //             this.loader = false
            //         })
            //     }
            // },
            // loadContractSalesItems(contract_id){
            //     this.getSalesItemsByContract(contract_id)
            // },
            loadInvoiceById (val) {
                let self = this
                this.loader = true
                this.getInvoiceToUpdateById(val)
                    .then(()=>{
                        this.Salesitem__gstpercent = this.Invoice__gstpercent
                        let payload = {
                            contract_id: this.Invoice__contract_id,
                            prefix: self.statePrefix
                        }
                        this.loader = false;
                        this.getSalesItemsByContract(payload)
                            .then(()=>{
                                this.loader = false;
                                this.updatedInvoiceUnsaved = false
                            })
                            .catch(()=>{
                                this.loader = false;
                                this.notLoaded = true
                                this.updatedInvoiceUnsaved = false
                            })
                            .finally(()=>{
                                this.loader = false;
                                this.updatedInvoiceUnsaved = false
                            })
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.invoiceNotLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loader = false
                        this.notLoaded = true
                    })
            },
            loadLocalSalesOrderItems(localsalesorder_id){
                this.getSalesItemsBySalesOrder(localsalesorder_id)
            },
            officeChanged(){
                // this.office.id = this.Contract__office_id
                // this.office.country_id = this.offices.find(f => f.Office.id == this.office.id).Office.country_id
            },
            parseFooterNote (footerNote) {
                if(footerNote.replace){
                    if(this.footerNoteReplacements[footerNote.replace] != null){
                        return footerNote.text.replace('<<' + footerNote.replace + '>>',this.footerNoteReplacements[footerNote.replace])
                    }
                } else {
                    return footerNote.text
                }
            },
            async postToGp(val) {
                if (await this.$root.$confirm(this.$t('message.postToGpQueue'), this.$t('message.confirmations.continuePostToGpQueue'), {color: 'orange'})) {
                    this.loading.post = true
                    let promise = new Promise((resolve, reject) => {
                        api
                            .put("/invoices/" + val + "/post-gp")
                            .then(response => {
                                if (response.data.status == 'success') {
                                    resolve(response.data)
                                } else {
                                    resolve('error')
                                }
                            })
                            .catch(error => {
                                reject(error);
                            })
                            .finally(() => {
                                resolve('error')
                            })
                    })
                    promise
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.invoicePosted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.Invoice__is_posted = response.is_posted
                                this.Invoice__posted = response.posted
                                this.Invoice__posted_by = response.posted_by
                                this.loading.post = false
                            } else {
                                this.$toast.error(this.$t('message.errors.invoiceNotPosted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.post = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.invoiceNotPosted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.post = false
                        })
                }
            },
            async resetInvoiceForm() {
                if (await this.$root.$confirm(this.$t('message.resetInvoiceForm'), this.$t('message.confirmations.continueInvoiceFormReset'), {color: 'orange'})) {
                    this.loading.reset = true
                    this.resetCurrentInvoice()
                        .then(() => {
                            this.resetInvoiceSalesItems(this.statePrefix)
                                .then(()=>{
                                    setTimeout(() => {
                                        this.newInvoiceSaved = false
                                        this.newInvoiceUnsaved = false
                                        this.loading.reset = false
                                        this.$toast.success(this.$t('message.invoiceFormResetNotification'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'check_circle_outline'
                                            }
                                        )
                                    }, 500)
                                })
                                .catch((e) => {
                                    this.$toast.success(e,
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    this.newInvoiceSaved = false
                                    this.newInvoiceUnsaved = false
                                    this.loading.reset = false
                                })
                        })
                        .catch((e) => {
                            this.$toast.success(e,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.newInvoiceSaved = false
                            this.newInvoiceUnsaved = false
                            this.loading.reset = false
                        })
                }
            },
            resetViewVars() {
                this.loading.invoicePdf = false
                this.loading.taxInvoicePdf = false
                this.viewPdf = false
                this.pdfProformaInvoice = false
            },
            saveInvoice() {
                let self = this
                if(this.$refs.invoiceForm.validate()) {
                    this.loading.save = true
                    this.updateInvoice()
                        .then((status) => {
                            if (status == 'done') {
                                let payload = {
                                    contract_id: this.Invoice__contract_id,
                                    prefix: self.statePrefix
                                }
                                this.getSalesItemsByContract(payload)
                                    .then(() => {
                                        this.$toast.success(this.$t('message.successes.invoiceUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'check_circle_outline'
                                            }
                                        )
                                        this.loading.save = false
                                        this.updatedInvoiceUnsaved = false
                                        this.pdfComponentKey = Math.floor(Math.random() * 100)
                                    })
                            } else {
                                this.$toast.error(this.$t('message.errors.invoiceNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.save = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.invoiceNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        })
                        .finally(() => {
                            this.loading.save = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.invoiceNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    scrollToFirstFormValidationError()
                }
            },
            saveTemporaryInvoiceFields() {
                this.loading.saveTemp = true
                this.updateTemporaryInvoiceFields()
                    .then(() => {
                        this.$toast.success(this.$t('message.successes.invoiceUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.loading.saveTemp = false
                    })
                    .catch((e) => {
                        this.$toast.error(e,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.saveTemp = false
                    })
            },
            viewInvoice(){
                this.loading.invoicePdf = true
                this.pdfTaxInvoice = false
                this.viewPdf = true
            },
            viewTaxInvoice() {
                this.loading.taxInvoicePdf = true
                this.pdfTaxInvoice = true
                this.viewPdf = true
            },
            viewTemporaryInvoicePdf() {
                let contractNumber = [this.Invoice__Contract__title, this.Invoice__Contract__revision_no , this.Invoice__Contract__partial_no].filter(Boolean).join('-')
                contractNumber = contractNumber.replace('.','')
                const document = encodeURIComponent(contractNumber + '_Inv_' + this.Invoice__invno)
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/converted-invoices/print/'
                    + this.invoiceId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewInvoicePdf () {
                let contractNumber = [this.Invoice__Contract__title, this.Invoice__Contract__revision_no , this.Invoice__Contract__partial_no].filter(Boolean).join('-')
                contractNumber = contractNumber.replace('.','')
                const document = encodeURIComponent(contractNumber + '_Inv_' + this.Invoice__invno)
                // let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/invoices/print/'
                    + this.invoiceId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewTaxInvoicePdf () {
                let contractNumber = [this.Invoice__Contract__title, this.Invoice__Contract__revision_no , this.Invoice__Contract__partial_no].filter(Boolean).join('-')
                contractNumber = contractNumber.replace('.','')
                let document = encodeURIComponent(contractNumber + '_Tax_Inv_' + this.Invoice__invno)
                // let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/tax-invoices/print/'
                    + this.invoiceId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            async uploadToXero (val) {
                if (await this.$root.$confirm(this.$t('message.uploadToXeroQueue'), this.$t('message.confirmations.continueUploadToXeroAction'), {color: 'orange'})) {
                    this.loading.xero = true
                    let promise = new Promise((resolve, reject) => {
                        api
                            .put("/invoices/" + val + "/upload-xero")
                            .then(response => {
                                if (response.data.status == 'success') {
                                    resolve(response.data)
                                } else {
                                    resolve(response.data.message)
                                }
                            })
                            .catch(error => {
                                reject(error);
                            })
                            .finally(() => {
                                resolve('error')
                            })
                    })
                    promise
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.invoiceUploaded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.Invoice__is_posted = 1
                                this.loading.xero = false
                            } else {
                                this.$toast.error(response,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.xero = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.invoiceNotUploaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.xero = false
                        })
                }
            }
        },
        watch: {
            // Invoice__Contract__id (val) {
            //     if(val != null) {
            //         let contract = this.getContractById(val)
            //         this.Invoice__Contract__bank_id = contract.Bank.id
            //         this.Invoice__Contract__currency_id = contract.Contract.currency_id
            //         this.Invoice__Contract__customer_id = contract.Customer.id
            //         this.Invoice__Contract__fsc = contract.Contract.fsc
            //         this.Invoice__Contract__incoterm_id = contract.Contract.incoterm_id
            //         this.Invoice__Contract__incotermport = contract.Contract.incotermport
            //         this.Invoice__Contract__loadingport_id = contract.Contract.loadingport_id
            //         this.Invoice__Contract__office_id = contract.Office.id
            //         this.Invoice__Contract__shippingport_id = contract.Contract.shippingport_id
            //         this.Invoice__Contract__title = contract.Contract.title
            //         if (contract.Contract.salestype_id == 5) {
            //             this.Invoice__currency_id = this.offices.find(office => office.Office.id === contract.Office.id)?.Office?.currency_id;
            //         } else {
            //             this.Invoice__currency_id = contract.Contract.currency_id
            //         }
            //         this.Invoice__contract_id = contract.Contract.id
            //         this.Invoice__Office__title = contract.Office.title
            //         this.Invoice__Customer__title = contract.Customer.title
            //         this.Invoice__Salescontact__name = contract.Salescontact.name
            //         this.Invoice__Paymentterm__id = contract.Paymentstatement.id
            //         this.Invoice__Paymentterm__title = contract.Paymentstatement.title
            //         this.loadContractSalesItems(val)
            //         this.loadingRelatedContract = false
            //         this.Invoice__headertaxschedule = this.offices.find(office => office.Office.id === contract.Office.id)?.Office?.taxcode
            //         this.Invoice__gstpercent = this.offices.find(office => office.Office.id === contract.Office.id)?.Office?.taxrate
            //     }
            // },
            Invoice__gstpercent (val) {
                this.Salesitem__gstpercent = val
            },
            Localsalesorder__id (val) {
                if(val != null) {
                    let salesorder = this.getSalesOrderById(val)
                    this.Customer__title = salesorder.Customer.title
                    this.Localsalesorder__id = salesorder.Localsalesorder.id
                    this.Localsalesorder__bank_id = salesorder.Bank.id
                    this.Localsalesorder__currency_id = salesorder.Localsalesorder.currency_id
                    this.Localsalesorder__customer_id = salesorder.Customer.id
                    this.Localsalesorder__office_id = salesorder.Office.id
                    this.Localsalesorder__title = salesorder.Localsalesorder.title
                    this.Invoice__currency_id = salesorder.Localsalesorder.currency_id
                    this.Office__title = salesorder.Office.title
                    this.Salescontact__name = salesorder.Salescontact.name
                    this.Paymentterm__id = salesorder.Paymentstatement.id
                    this.Paymentterm__title = salesorder.Paymentstatement.title
                    this.loadLocalSalesOrderItems(val)
                    this.loadingRelatedSalesOrder = false
                    this.Invoice__headertaxschedule = this.offices.find(office => office.Office.id === salesorder.Office.id)?.Office?.taxcode
                    this.Invoice__gstpercent = this.offices.find(office => office.Office.id === salesorder.Office.id)?.Office?.taxrate
                }
            },
            Invoice__type (val) {
                if(val == 'ds'){
                    let contract = this.getContractById(this.Invoice__Contract__id)
                    this.Invoice__Customer__title = contract.Customer.title
                    this.Invoice__Office__title = contract.Office.title
                    this.Invoice__Salescontact__name = contract.Salescontact.name
                    if(contract.Contract.salestype_id == 5){
                        this.Invoice__currency_id = this.offices.find(office => office.Office.id === contract.Office.id)?.Office?.currency_id;
                    } else {
                        this.Invoice__currency_id = contract.Contract.currency_id
                    }
                    this.Invoice__contract_id = contract.Contract.id
                    this.Invoice__Paymentterm__id = contract.Paymentstatement.id
                    this.Invoice__Paymentterm__title = contract.Paymentstatement.title
                    this.Invoice__headertaxschedule = this.offices.find(office => office.Office.id === contract.Office.id)?.Office?.taxcode
                    this.Invoice__gstpercent = this.offices.find(office => office.Office.id === contract.Office.id)?.Office?.taxrate
                } else if(val == 'wh'){
                    let salesorder = this.getSalesOrderById(this.Localsalesorder__id)
                    this.Customer__title = salesorder.Customer.title
                    this.Invoice__currency_id = salesorder.Localsalesorder.currency_id
                    this.Office__title = salesorder.Office.title
                    this.Salescontact__name = salesorder.Salescontact.name
                    this.Paymentterm__id = salesorder.Paymentstatement.id
                    this.Paymentterm__title = salesorder.Paymentstatement.title
                    this.Invoice__headertaxschedule = this.offices.find(office => office.Office.id === salesorder.Office.id).Office.taxcode
                    this.Invoice__gstpercent = this.offices.find(office => office.Office.id === salesorder.Office.id).Office.taxrate
                } else if(val == 'manual'){
                    this.Invoice__currency_id = this.Manual__Invoice__currency_id
                }
            },
            unInvoicedContract(val){
                log('Loading un-invoiced contract')
                let self = this
                setTimeout(function(){
                    self.loadUninvoicedContract(val)
                        .then(()=>{
                            let payload = {
                                contract_id: val.Contract.id,
                                prefix: self.statePrefix
                            }
                            self.getSalesItemsByContract(payload).then(()=>{
                                //assign unit prices to invoice unit price
                                self.Current__Salesitems.forEach(item => {
                                    item.Salesitem.invprice = item.Salesitem.unitprice
                                    if(item.type == 'othercharge'){
                                        item.Salesitem.invqty = 1
                                    }
                                    item.Salesitem.invoiceamount = item.Salesitem.invprice * item.Salesitem.invqty
                                })
                                self.updateTotals('current')
                                self.loader = false
                            })
                        })
                        .catch((err) => {
                            log(err)
                        })
                },100)
            }
        },
        created(){
            // Resets
            // this.$store.dispatch('resetConstantsState')
            // this.$store.dispatch('contract/resetAllContracts')
            // this.resetAllActiveInvoices()
            this.getAllContractsWithoutInvoice()
                .then((contracts) => {
                    // log(contracts)
                    this.allUnInvoicedContracts = contracts
                })
                .catch((err) => {
                    log(err)
                })
            if(this.allActiveInvoices.length == 0) this.getAllActiveInvoices()
            if(this.allBankAccounts.length == 0) this.getAllBankAccounts()
            // if(this.allUnInvoicedContracts.length == 0) this.getAllActiveContracts()
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            if(this.allUnInvoicedSalesOrders.length == 0) this.getAllActiveSalesOrders()
            if(this.$route.matched.some(({name}) => name === 'update_invoice')) {
                this.loadInvoiceById(this.invoiceId)
            } else if(this.$route.matched.some(({name}) => name === 'add_invoice')){
                this.newInvoiceUnsaved = true
                if(this.contractId != null){
                    this.loader = true
                    this.Invoice__Contract__id = this.contractId
                    let contract = this.allUnInvoicedContracts.find((d)=>{ return d.Contract.id == this.contractId})
                    if(contract) {
                        this.loadUninvoicedContract(contract)
                            .then(() => {
                                let payload = {
                                    contract_id: contract.Contract.id,
                                    prefix: 'current'
                                }
                                this.getSalesItemsByContract(payload).then(() => {
                                    //TODO assign unit prices to invoice unit price
                                    this.Current__Salesitems.forEach(item => {
                                        item.Salesitem.invprice = item.Salesitem.unitprice
                                        if(item.type == 'othercharge'){
                                            item.Salesitem.invqty = 1
                                        }
                                        item.Salesitem.invoiceamount = item.Salesitem.invprice * item.Salesitem.invqty
                                    })
                                    this.updateTotals('current')
                                    this.loader = false
                                })
                            })
                    } else {
                        alert('Invoice could not be initialised. Please contact APPIC developer for support.')
                    }
                }
            }
        },
        mounted(){
            this.$store.watch(
                function(state) {
                    return state.appic.invoice.update.Invoice
                },
                () => {
                    this.updatedInvoiceUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.invoice.current.Invoice
                },
                () => {
                    this.newInvoiceUnsaved = true
                },
                { deep: true }
            )
            if(this.$route.matched.some(({name}) => name === 'update_invoice')){
                setInterval(() => {
                    this.$title =  "Invoice: " + this.Invoice__invno
                }, 2000)
            } else {
                this.$title =  this.$t('message.titles.newInvoice')
            }
        }
    }
</script>

<style>
    .force-text-left .v-text-field__slot input {
        text-align: left !important;
    }
    .v-dialog {
        min-width: 200px !important;
    }
    .wrap-text {
        white-space: normal;
    }
    .font-xs {
        font-size: small;
    }
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }
    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
    .no-left-padding > div.v-expansion-panel-content__wrap{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
</style>